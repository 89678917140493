import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { systemMetaData } from 'src/query/systems/syntaxMetadata'
import {
  getProjectSystemTypeGroupsPerDomain,
  getDomainSystemTypeGroupUserDefinedFields,
  getSystemTypeGroup,
} from 'src/service/SystemTypeGroupService'

export const useSystemTypeGroup = (
  id: number,
  options: { enabled: boolean },
) => {
  return useQuery({
    queryKey: ['systemTypeGroup', id],
    queryFn: () => getSystemTypeGroup(id),
    ...options,
  })
}

export const useSystemTypeGroupForDomain = (domainName: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['systemTypeGroups', projectId, domainName],
    queryFn: () =>
      getProjectSystemTypeGroupsPerDomain(projectId, domainName).then((res) =>
        res.pop(),
      ),
  })
}

export const useSystemTypeGroups = (domainName: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['systemTypeGroups', projectId, domainName],
    queryFn: () => getProjectSystemTypeGroupsPerDomain(projectId, domainName),
  })
}

export const useDomainSystemTypeGroupMetaData = (domain: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [systemMetaData, projectId, domain],
    queryFn: () => getDomainSystemTypeGroupUserDefinedFields(projectId, domain),
  })
}
